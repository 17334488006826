$(document).foundation();

$('.leader-recommendations-slider').slick({
  lazyLoad: 'ondemand',
  infinite: true,
  dots: true,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  responsive: [
  
 {
   breakpoint: 640,
   settings: {
     slidesToShow: 1,
     slidesToScroll: 1
   }
 },
 {
   breakpoint: 1020,
   settings: {
     slidesToShow: 1,
     slidesToScroll: 1
   }
 }
 ]
});
